import React, { useEffect, useState } from "react";
import { ButtonSide } from "../assets/component/ButtonSide";
import Profil from "../assets/img/Pak Muchtarom.png";
import Notifikasi from "../assets/img/notif.svg";
import { Switch } from "@nextui-org/react";
import { NavbarResponsive } from "../assets/component/NavbarResponsive";
import { useNavigate } from "react-router-dom";

export const Home = () => {
  const [debitair, setdebitair] = useState([]);
  const navigate = useNavigate();
  const [irrigationSystemStatus, setIrrigationSystemStatus] = useState(false);

  // get debit air
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://adosistering-default-rtdb.asia-southeast1.firebasedatabase.app/SensorWaterFlow.json");

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setdebitair(data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  console.log(debitair, "debit ait");

  // put sistem irigasi
  const handleToggleIrrigationSystem = async () => {
    try {
      const response = await fetch("https://adosistering-default-rtdb.asia-southeast1.firebasedatabase.app/IsSprayActive.json", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ isSprayActive: !irrigationSystemStatus }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      setIrrigationSystemStatus(!irrigationSystemStatus);
    } catch (error) {
      console.error("Error toggling irrigation system: ", error);
    }
  };

  // klik
  const [clickedButton, setClickedButton] = useState({
    "5:00": false,
    "10:00": false,
    "15:00": false,
    "20:00": false,
    "25:00": false,
    "30:00": false,
    "45:00": false,
    "1 Jam": false,
  });

  const buttonKeys = Object.keys(clickedButton);
  const halfLength = Math.ceil(buttonKeys.length / 2);
  const firstHalf = buttonKeys.slice(0, halfLength);
  const secondHalf = buttonKeys.slice(halfLength);

  const handleButtonClick = (time) => {
    // Reset all buttons to false
    const updatedButtons = {};
    for (const key in clickedButton) {
      updatedButtons[key] = false;
    }
    // Set the clicked button to true
    updatedButtons[time] = true;
    setClickedButton(updatedButtons);
  };

  return (
    <div className=" flex w-full h-full bg-[#EBECF0]">
      <div className="flex w-full h-fit  ">
        <ButtonSide />

        <div className="w-full h-full  flex flex-col justify-center items-center desktop:space-y-8 mobile:space-y-4">
          <div className="p-6 bg-[#F8F9FD] desktop:h-[100px] mobile:h-[61px] flex w-full  justify-between desktop:flex mobile:hidden">
            <h1 className="font-semibold text-[#5BB318]  desktop:text-[32px] ">Dashboard</h1>
            <div className="flex w-full justify-end items-center desktop:gap-4 mobile:gap-3">
              {/* <button className="" onClick={() => navigate("/Notifikasi")}>
              <img className=" " src={Notifikasi} alt="" />
              </button> */}

              <img className=" " src={Profil} alt="Profil" />
              <span className="mobile:hidden desktop:flex flex-col text-[#5BB318] text-[16px]">
                <p className="">Selamat Datang !</p>
                <p className="font-medium">Muchtar</p>
              </span>
            </div>
          </div>
          <NavbarResponsive className="desktop:hidden" />
          <div className="w-full desktop:px-10 mobile:px-3 desktop:space-y-4 mobile:space-y-4 flex flex-col items-center justify-center">
            <div className=" w-full flex items-center justify-center bg-[#F8F9FD]  desktop:h-[64px] mobile:h-[43px] rounded-lg">
              <h1 className="font-semibold text-[#5BB318] desktop:text-2xl mobile:text-base ">Daftar Lahan</h1>
            </div>
            <div className=" flex flex-wrap items-center mobile:justify-center desktop:justify-between gap-6 w-full  ">
              <div className=" bg-[#F8F9FD]  flex flex-col justify-center px-6 desktop:w-[352px] mobile:w-full    text-[#3F4044]   py-6 rounded-lg space-y-2 ">
                <h1 className="font-medium desktop:text-2xl mobile:text-base  ">Lahan 1</h1>
                {/* <p className="desktop:text-sm mobile:text-xs ">Pilih Waktu Irigasi</p>
                <div className="flex flex-col gap-2  text-[#A9AAAE] text-sm">
                  <div className="flex justify-between  gap-2 ">
                    {firstHalf.map((time) => (
                      <button
                        key={time}
                        className={`px-4 py-2 w-[70px] h-[37px] rounded-[4px] ${clickedButton[time] ? "bg-[#5BB318] font-semibold text-white" : "bg-[#EBECF0] hover:bg-[#ADD98B] hover:text-[#1E3C08] hover:font-semibold "}`}
                        onClick={() => handleButtonClick(time)}
                      >
                        {time}
                      </button>
                    ))}
                  </div>
                  <div className="flex justify-between gap-2 ">
                    {secondHalf.map((time) => (
                      <button
                        key={time}
                        className={`px-3 py-2 w-[70px] h-[37px] rounded-[4px] ${clickedButton[time] ? "bg-[#5BB318] font-semibold text-white" : "bg-[#EBECF0] hover:bg-[#ADD98B] hover:text-[#1E3C08] hover:font-semibold"}`}
                        onClick={() => handleButtonClick(time)}
                      >
                        {time}
                      </button>
                    ))}
                  </div>
                </div> */}
                <div className="flex flex-col gap-2.5  text-sm">
                  {/* <div className="flex justify-between py-1 desktop:text-sm mobile:text-xs">
                    <p className=" ">Sisa Waktu </p>
                    <p className="text-[#818286]  font-medium">44 : 59</p>
                  </div> */}
                  <div className="flex justify-between items-center desktop:text-sm mobile:text-xs py-1">
                    <p className=" ">Debit Air </p>
                    <p className="text-[#4C9514] bg-[#CBF7A8] px-3 py-1 rounded-xl font-medium">{debitair.DebitAir}L / Menit</p>
                  </div>
                  <div className="flex justify-between items-center desktop:text-sm mobile:text-xs py-1">
                    <p className=" ">Jumlah Air Keluar </p>
                    <p className="text-[#4C9514] bg-[#CBF7A8] px-3 py-1 rounded-xl font-medium">{debitair.TotalAir} L</p>
                  </div>
                  {/* <div className="flex justify-between  desktop:text-sm mobile:text-xs py-1">
                    <p className=" ">Sistem Irigasi</p>
                    <label htmlFor="AcceptConditions1" className="relative h-6 w-12 cursor-pointer rounded-full bg-gray-300 transition [-webkit-tap-highlight-color:_transparent] has-[:checked]:bg-[#5BB318]">
                      <input value={irrigationSystemStatus} onChange={handleToggleIrrigationSystem} type="checkbox" id="AcceptConditions1" className="peer sr-only" />
                      <span className="absolute inset-y-0 start-0 m-1 size-4 rounded-full bg-white transition-all peer-checked:start-6"></span>
                    </label>

                    {/* <Switch color="success" value={irrigationSystemStatus} onChange={handleToggleIrrigationSystem} /> */}
                  {/* </div> */}
                </div>
              </div>
              {/* <div className=" bg-[#ffffff] opacity-55  flex flex-col justify-center px-6   text-[#3F4044]   py-6 rounded-lg space-y-2 ">
                <h1 className="font-medium desktop:text-2xl mobile:text-base  ">Lahan 1</h1>
                <p className="desktop:text-sm mobile:text-xs ">Pilih Waktu Irigasi</p>
                <div className="flex flex-col gap-2  text-[#A9AAAE] text-sm">
                  <div className="flex gap-2  ">
                    <p className="bg-[#EBECF0] px-4 py-2 rounded-[4px] ">20:00</p>
                    <p className="bg-[#EBECF0] px-4 py-2 rounded-[4px] ">20:00</p>
                    <p className="bg-[#EBECF0] px-4 py-2 rounded-[4px] ">20:00</p>
                    <p className="bg-[#EBECF0] px-4 py-2 rounded-[4px] ">20:00</p>
                  </div>
                  <div className="flex gap-2 ">
                    <p className="bg-[#EBECF0] px-4 py-2 rounded-[4px] ">20:00</p>
                    <p className="bg-[#EBECF0] px-4 py-2 rounded-[4px] ">20:00</p>
                    <p className="bg-[#EBECF0] px-4 py-2 rounded-[4px] ">20:00</p>
                    <p className="bg-[#EBECF0] px-4 py-2 rounded-[4px] ">20:00</p>
                  </div>
                </div>
                <div className="flex flex-col gap-2.5  text-sm">
                  <div className="flex justify-between py-1 desktop:text-sm mobile:text-xs">
                    <p className=" ">Sisa Waktu </p>
                    <p className="text-[#818286]  font-medium">44 : 59</p>
                  </div>
                  <div className="flex justify-between desktop:text-sm mobile:text-xs py-1">
                    <p className=" ">Debit Air </p>
                    <p className="text-[#818286] font-medium">3L / Menit</p>
                  </div>
                  <div className="flex justify-between desktop:text-sm mobile:text-xs py-1">
                    <p className=" ">Jumlah Air Keluar </p>
                    <p className="text-[#818286] font-medium">15 L</p>
                  </div>
                  <div className="flex justify-between  desktop:text-sm mobile:text-xs py-1">
                    <p className=" ">Sistem Irigasi</p>
                    <label htmlFor="AcceptConditions1" className="relative h-8 w-14 cursor-pointer rounded-full bg-gray-300 transition [-webkit-tap-highlight-color:_transparent] has-[:checked]:bg-[#5BB318]">
                      <input type="checkbox" id="AcceptConditions1" className="peer sr-only" />
                      <span className="absolute inset-y-0 start-0 m-1 size-6 rounded-full bg-white transition-all peer-checked:start-6"></span>
                    </label>
                  </div>
                </div>
              </div> */}
              {/* <div className=" bg-[#ffffff] opacity-55  flex flex-col justify-center px-6   text-[#3F4044]   py-6 rounded-lg space-y-2 ">
                <h1 className="font-medium desktop:text-2xl mobile:text-base  ">Lahan 1</h1>
                <p className="desktop:text-sm mobile:text-xs ">Pilih Waktu Irigasi</p>
                <div className="flex flex-col gap-2  text-[#A9AAAE] text-sm">
                  <div className="flex gap-2  ">
                    <p className="bg-[#EBECF0] px-4 py-2 rounded-[4px] ">20:00</p>
                    <p className="bg-[#EBECF0] px-4 py-2 rounded-[4px] ">20:00</p>
                    <p className="bg-[#EBECF0] px-4 py-2 rounded-[4px] ">20:00</p>
                    <p className="bg-[#EBECF0] px-4 py-2 rounded-[4px] ">20:00</p>
                  </div>
                  <div className="flex gap-2 ">
                    <p className="bg-[#EBECF0] px-4 py-2 rounded-[4px] ">20:00</p>
                    <p className="bg-[#EBECF0] px-4 py-2 rounded-[4px] ">20:00</p>
                    <p className="bg-[#EBECF0] px-4 py-2 rounded-[4px] ">20:00</p>
                    <p className="bg-[#EBECF0] px-4 py-2 rounded-[4px] ">20:00</p>
                  </div>
                </div>
                <div className="flex flex-col gap-2.5  text-sm">
                  <div className="flex justify-between py-1 desktop:text-sm mobile:text-xs">
                    <p className=" ">Sisa Waktu </p>
                    <p className="text-[#818286]  font-medium">44 : 59</p>
                  </div>
                  <div className="flex justify-between desktop:text-sm mobile:text-xs py-1">
                    <p className=" ">Debit Air </p>
                    <p className="text-[#818286] font-medium">3L / Menit</p>
                  </div>
                  <div className="flex justify-between desktop:text-sm mobile:text-xs py-1">
                    <p className=" ">Jumlah Air Keluar </p>
                    <p className="text-[#818286] font-medium">15 L</p>
                  </div>
                  <div className="flex justify-between  desktop:text-sm mobile:text-xs py-1">
                    <p className=" ">Sistem Irigasi</p>
                    <label htmlFor="AcceptConditions1" className="relative h-8 w-14 cursor-pointer rounded-full bg-gray-300 transition [-webkit-tap-highlight-color:_transparent] has-[:checked]:bg-green-500">
                      <input type="checkbox" id="AcceptConditions1" className="peer sr-only" />
                      <span className="absolute inset-y-0 start-0 m-1 size-6 rounded-full bg-white transition-all peer-checked:start-6"></span>
                    </label>
                  </div>
                </div>
              </div> */}
              {/* <div className=" bg-[#ffffff] opacity-55  flex flex-col justify-center px-6   text-[#3F4044]   py-6 rounded-lg space-y-2 ">
                <h1 className="font-medium desktop:text-2xl mobile:text-base  ">Lahan 1</h1>
                <p className="desktop:text-sm mobile:text-xs ">Pilih Waktu Irigasi</p>
                <div className="flex flex-col gap-2  text-[#A9AAAE] text-sm">
                  <div className="flex gap-2  ">
                    <p className="bg-[#EBECF0] px-4 py-2 rounded-[4px] ">20:00</p>
                    <p className="bg-[#EBECF0] px-4 py-2 rounded-[4px] ">20:00</p>
                    <p className="bg-[#EBECF0] px-4 py-2 rounded-[4px] ">20:00</p>
                    <p className="bg-[#EBECF0] px-4 py-2 rounded-[4px] ">20:00</p>
                  </div>
                  <div className="flex gap-2 ">
                    <p className="bg-[#EBECF0] px-4 py-2 rounded-[4px] ">20:00</p>
                    <p className="bg-[#EBECF0] px-4 py-2 rounded-[4px] ">20:00</p>
                    <p className="bg-[#EBECF0] px-4 py-2 rounded-[4px] ">20:00</p>
                    <p className="bg-[#EBECF0] px-4 py-2 rounded-[4px] ">20:00</p>
                  </div>
                </div>
                <div className="flex flex-col gap-2.5  text-sm">
                  <div className="flex justify-between py-1 desktop:text-sm mobile:text-xs">
                    <p className=" ">Sisa Waktu </p>
                    <p className="text-[#818286]  font-medium">44 : 59</p>
                  </div>
                  <div className="flex justify-between desktop:text-sm mobile:text-xs py-1">
                    <p className=" ">Debit Air </p>
                    <p className="text-[#818286] font-medium">3L / Menit</p>
                  </div>
                  <div className="flex justify-between desktop:text-sm mobile:text-xs py-1">
                    <p className=" ">Jumlah Air Keluar </p>
                    <p className="text-[#818286] font-medium">15 L</p>
                  </div>
                  <div className="flex justify-between  desktop:text-sm mobile:text-xs py-1">
                    <p className=" ">Sistem Irigasi</p>
                    <label htmlFor="AcceptConditions1" className="relative h-8 w-14 cursor-pointer rounded-full bg-gray-300 transition [-webkit-tap-highlight-color:_transparent] has-[:checked]:bg-green-500">
                      <input type="checkbox" id="AcceptConditions1" className="peer sr-only" />
                      <span className="absolute inset-y-0 start-0 m-1 size-6 rounded-full bg-white transition-all peer-checked:start-6"></span>
                    </label>
                  </div>
                </div>
              </div> */}
              {/* <div className=" bg-[#ffffff] opacity-55  flex flex-col justify-center px-6   text-[#3F4044]   py-6 rounded-lg space-y-2 ">
                <h1 className="font-medium desktop:text-2xl mobile:text-base  ">Lahan 1</h1>
                <p className="desktop:text-sm mobile:text-xs ">Pilih Waktu Irigasi</p>
                <div className="flex flex-col gap-2  text-[#A9AAAE] text-sm">
                  <div className="flex gap-2  ">
                    <p className="bg-[#EBECF0] px-4 py-2 rounded-[4px] ">20:00</p>
                    <p className="bg-[#EBECF0] px-4 py-2 rounded-[4px] ">20:00</p>
                    <p className="bg-[#EBECF0] px-4 py-2 rounded-[4px] ">20:00</p>
                    <p className="bg-[#EBECF0] px-4 py-2 rounded-[4px] ">20:00</p>
                  </div>
                  <div className="flex gap-2 ">
                    <p className="bg-[#EBECF0] px-4 py-2 rounded-[4px] ">20:00</p>
                    <p className="bg-[#EBECF0] px-4 py-2 rounded-[4px] ">20:00</p>
                    <p className="bg-[#EBECF0] px-4 py-2 rounded-[4px] ">20:00</p>
                    <p className="bg-[#EBECF0] px-4 py-2 rounded-[4px] ">20:00</p>
                  </div>
                </div>
                <div className="flex flex-col gap-2.5  text-sm">
                  <div className="flex justify-between py-1 desktop:text-sm mobile:text-xs">
                    <p className=" ">Sisa Waktu </p>
                    <p className="text-[#818286]  font-medium">44 : 59</p>
                  </div>
                  <div className="flex justify-between desktop:text-sm mobile:text-xs py-1">
                    <p className=" ">Debit Air </p>
                    <p className="text-[#818286] font-medium">3L / Menit</p>
                  </div>
                  <div className="flex justify-between desktop:text-sm mobile:text-xs py-1">
                    <p className=" ">Jumlah Air Keluar </p>
                    <p className="text-[#818286] font-medium">15 L</p>
                  </div>
                  <div className="flex justify-between  desktop:text-sm mobile:text-xs py-1">
                    <p className=" ">Sistem Irigasi</p>
                    <label htmlFor="AcceptConditions1" className="relative h-8 w-14 cursor-pointer rounded-full bg-gray-300 transition [-webkit-tap-highlight-color:_transparent] has-[:checked]:bg-green-500">
                      <input type="checkbox" id="AcceptConditions1" className="peer sr-only" />
                      <span className="absolute inset-y-0 start-0 m-1 size-6 rounded-full bg-white transition-all peer-checked:start-6"></span>
                    </label>
                  </div>
                </div>
              </div> */}
              {/* <div className=" bg-[#ffffff] opacity-55  flex flex-col justify-center px-6   text-[#3F4044]   py-6 rounded-lg space-y-2 ">
                <h1 className="font-medium desktop:text-2xl mobile:text-base  ">Lahan 1</h1>
                <p className="desktop:text-sm mobile:text-xs ">Pilih Waktu Irigasi</p>
                <div className="flex flex-col gap-2  text-[#A9AAAE] text-sm">
                  <div className="flex gap-2  ">
                    <p className="bg-[#EBECF0] px-4 py-2 rounded-[4px] ">20:00</p>
                    <p className="bg-[#EBECF0] px-4 py-2 rounded-[4px] ">20:00</p>
                    <p className="bg-[#EBECF0] px-4 py-2 rounded-[4px] ">20:00</p>
                    <p className="bg-[#EBECF0] px-4 py-2 rounded-[4px] ">20:00</p>
                  </div>
                  <div className="flex gap-2 ">
                    <p className="bg-[#EBECF0] px-4 py-2 rounded-[4px] ">20:00</p>
                    <p className="bg-[#EBECF0] px-4 py-2 rounded-[4px] ">20:00</p>
                    <p className="bg-[#EBECF0] px-4 py-2 rounded-[4px] ">20:00</p>
                    <p className="bg-[#EBECF0] px-4 py-2 rounded-[4px] ">20:00</p>
                  </div>
                </div>
                <div className="flex flex-col gap-2.5  text-sm">
                  <div className="flex justify-between py-1 desktop:text-sm mobile:text-xs">
                    <p className=" ">Sisa Waktu </p>
                    <p className="text-[#818286]  font-medium">44 : 59</p>
                  </div>
                  <div className="flex justify-between desktop:text-sm mobile:text-xs py-1">
                    <p className=" ">Debit Air </p>
                    <p className="text-[#818286] font-medium">3L / Menit</p>
                  </div>
                  <div className="flex justify-between desktop:text-sm mobile:text-xs py-1">
                    <p className=" ">Jumlah Air Keluar </p>
                    <p className="text-[#818286] font-medium">15 L</p>
                  </div>
                  <div className="flex justify-between  desktop:text-sm mobile:text-xs py-1">
                    <p className=" ">Sistem Irigasi</p>
                    <label htmlFor="AcceptConditions1" className="relative h-8 w-14 cursor-pointer rounded-full bg-gray-300 transition [-webkit-tap-highlight-color:_transparent] has-[:checked]:bg-green-500">
                      <input type="checkbox" id="AcceptConditions1" className="peer sr-only" />
                      <span className="absolute inset-y-0 start-0 m-1 size-6 rounded-full bg-white transition-all peer-checked:start-6"></span>
                    </label>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
