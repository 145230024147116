import React, { useEffect, useState } from "react";
import { ButtonSide } from "../assets/component/ButtonSide";
import Profil from "../assets/img/Pak Muchtarom.png";
import Notifikasi from "../assets/img/notif.svg";
import { NavbarResponsive } from "../assets/component/NavbarResponsive";
import { useNavigate } from "react-router-dom";

export const Riwayat = () => {
  const navigate = useNavigate();
  const [debitair, setdebitair] = useState([]);
  // get debit air
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://adosistering-default-rtdb.asia-southeast1.firebasedatabase.app/SensorWaterFlow.json");

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setdebitair(data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="h-[100%]  bg-[#EBECF0]">
      <div className="flex  w-full h-full  ">
        <ButtonSide />
        <div className="w-full h-full  flex flex-col  desktop:space-y-8 mobile:space-y-4">
          <div className="p-6 bg-[#F8F9FD] desktop:h-[100px] mobile:h-[61px] flex w-full justify-between desktop:flex mobile:hidden">
            <h1 className="font-semibold text-[#5BB318]  desktop:text-[32px] ">Riwayat</h1>
            <div className="flex w-full justify-end items-center desktop:gap-4 mobile:gap-3">
              {/* <button className="" onClick={() => navigate("/Notifikasi")}>
              <img className=" " src={Notifikasi} alt="" />
              </button> */}
              <img className=" " src={Profil} alt="Profil" />
              <span className="mobile:hidden desktop:flex flex-col text-[#5BB318] text-[16px]">
                <p className="">Selamat Datang !</p>
                <p className="font-medium">Muchtar</p>
              </span>
            </div>
          </div>
          <NavbarResponsive className="desktop:hidden" />
          <div className=" w-full desktop:px-10 mobile:px-3 desktop:space-y-4 mobile:space-y-4 mobile:hidden desktop:flex flex-col items-center justify-center">
            <div className=" w-full flex items-center justify-center bg-[#F8F9FD]  desktop:h-[64px] mobile:h-[43px] rounded-lg">
              <h1 className="font-semibold text-[#5BB318] desktop:text-2xl mobile:text-base ">Riwayat Irigasi</h1>
            </div>
            <div className="w-full bg-[#F8F9FD] px-2 text-[#2E5A0C] font-medium text-lg  h-[4rem] flex items-center justify-around rounded-[6px] ">
              <p className=" w-[166px] h-[35px] rounded-md items-center flex justify-center">Tanggal Irigasi</p>
              <p className=" w-[166px] h-[35px] rounded-md items-center flex justify-center">Nama Lahan</p>
              {/* <p className=" w-[166px] h-[35px] rounded-md items-center flex justify-center">Durasi Irigasi</p> */}
              <p className=" w-[166px] h-[35px] rounded-md items-center flex justify-center">Jumlah Air</p>
              <p className=" w-[166px] h-[35px] rounded-md items-center flex justify-center">Debit Air</p>
              <p className=" w-[166px] h-[35px] rounded-md items-center flex justify-center">Waktu</p>
            </div>
            <div className="w-full bg-[#F8F9FD] h-[67px] px-2 flex items-center justify-around rounded-lg text-[#2E5A0C] font-medium ">
              <p className="bg-[#CBF7A8] w-[166px] h-[35px] rounded-md items-center flex justify-center">20/02/2024</p>
              <p className="bg-[#CBF7A8] w-[166px] h-[35px] rounded-md items-center flex justify-center">Lahan 1</p>
              {/* <p className="bg-[#CBF7A8] w-[166px] h-[35px] rounded-md items-center flex justify-center">45.00</p> */}
              <p className="bg-[#CBF7A8] w-[166px] h-[35px] rounded-md items-center flex justify-center">{debitair.TotalAir} L</p>
              <p className="bg-[#CBF7A8] w-[166px] h-[35px] rounded-md items-center flex justify-center">{debitair.DebitAir} L / Menit</p>
              <p className="bg-[#CBF7A8] w-[166px] h-[35px] rounded-md items-center flex justify-center">23 : 00 WIB</p>
            </div>
            {/* <div className="w-full bg-[#F8F9FD]  h-[96px] flex items-center justify-center rounded-lg">
              <h1 className="text-2xl  text-[#A9AAAE]">Belum Ada Data Irigasi</h1>
            </div> */}
          </div>
          <div className=" w-full  desktop:px-10 mobile:px-3 desktop:space-y-4 mobile:space-y-4 desktop:hidden mobile:flex mobile:flex-col mobile:items-center justify-center">
            <div className=" w-full flex items-center justify-center bg-[#F8F9FD]  desktop:h-[64px] mobile:h-[43px] rounded-lg">
              <h1 className="font-semibold text-[#5BB318] desktop:text-2xl mobile:text-base ">Riwayat Irigasi</h1>
            </div>
            <div className="bg-[#F8F9FD] p-4 w-full rounded-lg space-y-2 text-xs text-[#2E5A0C]">
              <div className="flex justify-between ">
                <div className="">
                  <p className="font-semibold">Nama Lahan</p>
                  <p>Lahan 1</p>
                </div>
                <div>
                  <p>20/02/24</p>
                  <p>23 : 00 WIB</p>
                </div>
              </div>
              <div className="flex gap-10">
                {/* <div className="space-y-2">
                  <p className="font-semibold">Durasi Irigasi</p>
                  <p>45:00</p>
                </div> */}
                <div className="space-y-2">
                  <p className="font-semibold">Jumlah Air</p>
                  <p>{debitair.TotalAir} L</p>
                </div>
                <div className="space-y-2">
                  <p className="font-semibold">Debit Air</p>
                  <p>{debitair.DebitAir} L / Menit</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
