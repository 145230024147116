import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "../pages/Home";
import { Login } from "../pages/Login";
import { Riwayat } from "../pages/Riwayat";
// import { Notifikasi } from "../pages/Notifikasi";
import { Status } from "../pages/Status";
// import { Test } from "../pages/test";

export const RouterList = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/Riwayat" element={<Riwayat />} />
        {/* <Route path="/Notifikasi" element={<Notifikasi />} /> */}
        <Route path="/Status" element={<Status />} />
        {/* <Route path="/test" element={<Test />} /> */}
      </Routes>
    </BrowserRouter>
  );
};
